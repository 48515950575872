import React, { useReducer, useCallback } from 'react';
import auth0 from 'auth0-js';
import jwt from 'jwt-decode';
import authReducer, { getAuth0UserId } from './AuthReducer';
import {
  AuthContext,
  setLocalStorageAccessAuth,
  deleteLocalStorageAuth,
  deleteAuthCookie,
  setAuthCookie,
  isLocalhost,
  platformActions,
  platformSelectors,
  cookieService,
  trackEvent,
  eventRequestTypes,
  useGetActiveTool,
} from '@clatter/platform';
import { useDispatch, useSelector } from 'react-redux';

export const authActions = {
  SET_IMPERSONATED_USER: 'auth_set_impersonated_user',
  SET_USER: 'auth_set_user',
  LOGOUT: 'auth_logout',
  SET_IS_LOADING: 'auth_is_loading',
  ERROR: 'auth_error',
};

const auth0ConnectionName =
  process.env.NX_AUTH0_CONNECTION_NAME || 'Username-Password-Authentication';
const webAuth = new auth0.WebAuth({
  domain: process.env.NX_AUTH0_DOMAIN,
  clientID: process.env.NX_AUTH0_CLIENT_ID,
  audience: process.env.NX_AUTH0_AUDIENCE,
  realm: auth0ConnectionName,
  redirectUri: typeof window === 'object' ? window.location.origin : '/', // window is not defined in msm-next SSR
  scope: 'openid profile email',
  responseType: 'token id_token',
});

const initialState = {
  error: undefined,
  user: undefined,
  impersonate: undefined,
  isLoading: true, // will be updated once `checkSession()` finish
  isAuthenticated: false,
};

function AuthState({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const reduxDispatch = useDispatch();
  const activeTool = useGetActiveTool();
  const impersonatedUser = useSelector(
    platformSelectors.impersonate.selectImpersonatedUser,
  );

  const changePassword = useCallback(
    (email, callback) =>
      webAuth.changePassword(
        { connection: auth0ConnectionName, email: email },
        callback,
      ),
    [],
  );

  const login = useCallback(
    async ({ username, password, redirectTo }, callback) => {
      dispatch({
        type: authActions.SET_IS_LOADING,
      });
      return webAuth.login(
        {
          username: username,
          password: password,
          popup: !isLocalhost(), // show authorize app on localhost
          redirectUri: redirectTo,
        },
        (error, authResult) => {
          if (!error) {
            return setSession('login', authResult, callback);
          }
          deleteAuthCookie();
          deleteLocalStorageAuth();
          dispatch({ type: authActions.LOGOUT });
          return callback && callback(error, authResult);
        },
      );
    },
    [dispatch],
  );

  const loginWithAccessToken = useCallback(
    async ({ accessToken, impersonateUser }, callback) => {
      dispatch({
        type: authActions.SET_IS_LOADING,
      });

      try {
        const jwtObject = jwt(accessToken);

        const jwtAudience = (() => {
          if (!jwtObject?.aud) {
            return [];
          }

          if (Array.isArray(jwtObject?.aud)) {
            return jwtObject?.aud;
          }

          return [jwtObject?.aud];
        })();

        if (!jwtAudience.includes(process.env.NX_AUTH0_AUDIENCE)) {
          throw new Error('Invalid JWT specified.');
        }

        const request = await fetch(
          `https://${process.env.NX_AUTH0_DOMAIN}/userinfo`,
          {
            method: 'get',
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
            }),
          },
        );
        const userData = await request.json();
        cookieService.set('pm_access_token', accessToken, jwtObject.exp);

        impersonateUser && setImpersonatedUser(impersonateUser);
        return setSession(
          'loginWithAccessToken',
          {
            idToken: null,
            idTokenPayload: {
              ...userData,
              exp: jwtObject.exp,
            },
            accessToken: accessToken,
          },
          callback,
        );
      } catch (error) {
        deleteAuthCookie();
        deleteLocalStorageAuth();
        await dispatch({ type: authActions.LOGOUT });
        return callback && callback(error, undefined);
      }
    },
    [dispatch],
  );

  const loginWithSSO = useCallback(async () => {
    dispatch({
      type: authActions.SET_IS_LOADING,
    });

    return webAuth.authorize({
      connection: process.env.NX_SSO_AUTH0_CONNECTION_NAME,
      redirect_uri: `${window.location.origin}/sso/callback`,
    });
  }, [dispatch]);

  const setImpersonatedUser = useCallback(
    async (user) => {
      await reduxDispatch(platformActions.impersonate.set(user));
      return dispatch({
        type: authActions.SET_IMPERSONATED_USER,
        payload: {
          user: user,
        },
      });
    },
    [dispatch, reduxDispatch],
  );

  const checkSession = useCallback(
    (callback) => {
      dispatch({
        type: authActions.SET_IS_LOADING,
      });

      return webAuth.checkSession(
        {
          audience: process.env.NX_AUTH0_AUDIENCE,
          scope: 'openid profile email',
          responseType: 'token id_token',
          clientID: process.env.NX_AUTH0_CLIENT_ID,
        },
        async (error, authResult) => {
          if (!error) {
            if (impersonatedUser) {
              await dispatch(setImpersonatedUser(impersonatedUser));
            }
            return setSession('checkSession', authResult, callback);
          }
          deleteLocalStorageAuth();
          deleteAuthCookie();
          dispatch({ type: authActions.LOGOUT });
          return callback && callback(error, authResult);
        },
      );
    },
    [dispatch],
  );

  const logout = useCallback(({ returnTo, clientID }) => {
    // clear redux state
    setImpersonatedUser(null);

    // clear OMNI pm attributes
    reduxDispatch(
      platformActions.persisted.set({
        type: 'pm_attributes',
        data: [],
      }),
    );

    // delete auth cookie
    deleteLocalStorageAuth();
    deleteAuthCookie();

    dispatch({
      type: authActions.LOGOUT,
    });
    // logout from auth0
    webAuth.logout({
      returnTo: returnTo || window.location.origin,
      clientID: clientID || process.env.NX_AUTH0_CLIENT_ID,
    });
  }, []);

  const setSession = useCallback(
    async (actionType, authResult, callback) => {
      setLocalStorageAccessAuth(authResult);
      setAuthCookie(authResult);

      await dispatch({
        type: authActions.SET_USER,
        payload: {
          user: authResult.idTokenPayload,
          accessToken: authResult.accessToken,
          idToken: authResult.idToken,
        },
      });

      // this action is call only when webAuth.login:popup === true, so NOT on localhost!
      if (actionType === 'login') {
        reduxDispatch(
          trackEvent({
            type: eventRequestTypes.userLogin,
            data: {
              tool: activeTool?.slug?.toUpperCase()
                || (process.env.NX_TOOL_NAME !== 'PM' ? process.env.NX_TOOL_NAME : 'PG'),
              user_id: getAuth0UserId(authResult.idTokenPayload.sub),
              user_name: authResult.idTokenPayload.name,
              user_email: authResult.idTokenPayload.email,
              connection: process.env.NX_AUTH0_CONNECTION_NAME,
            },
          }),
        );
      } else if (actionType === 'checkSession') {
        reduxDispatch(
          trackEvent({
            type: eventRequestTypes.tokenRefreshed,
            data: {
              tool: activeTool?.slug?.toUpperCase()
                || (process.env.NX_TOOL_NAME !== 'PM' ? process.env.NX_TOOL_NAME : 'PG'),
              user_id: getAuth0UserId(authResult.idTokenPayload.sub),
              user_name: authResult.idTokenPayload.name,
              user_email: authResult.idTokenPayload.email,
            },
          }),
        );
      }

      return callback && callback(undefined, authResult);
    },
    [dispatch, reduxDispatch],
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        isAuthenticated: state.isAuthenticated,
        login,
        setImpersonatedUser,
        loginWithAccessToken,
        loginWithSSO,
        changePassword,
        checkSession,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthState;
